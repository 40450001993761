// extracted by mini-css-extract-plugin
export var maineDigest = "maine_digest_2022_06-module--maineDigest--idHP6";
export var heroImage = "maine_digest_2022_06-module--heroImage--Mi90W";
export var columnWrapper = "maine_digest_2022_06-module--columnWrapper--DR8FF";
export var headingLogo = "maine_digest_2022_06-module--headingLogo--PcJh4";
export var headingWrapper = "maine_digest_2022_06-module--headingWrapper--GIg3y";
export var heading = "maine_digest_2022_06-module--heading--LiLhN";
export var instance = "maine_digest_2022_06-module--instance--1ChkA";
export var subheading = "maine_digest_2022_06-module--subheading--Ynalg";
export var textWrapper = "maine_digest_2022_06-module--textWrapper---vrKU";
export var latestnewsarticleheadline = "maine_digest_2022_06-module--latestnewsarticleheadline--wBE82";
export var dateline = "maine_digest_2022_06-module--dateline--AR4EY";
export var whatsNew = "maine_digest_2022_06-module--whatsNew--4bq8M";
export var colWrapper = "maine_digest_2022_06-module--colWrapper--weOAb";
export var leftCol = "maine_digest_2022_06-module--leftCol--2kGFB";
export var rightCol = "maine_digest_2022_06-module--rightCol--zw5UY";
export var photoCaption = "maine_digest_2022_06-module--photoCaption--zVx2R";
export var afterCaption = "maine_digest_2022_06-module--afterCaption--fLKG-";