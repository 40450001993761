import React, { ReactComponent } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';

import BendableLabsLogo from '../components/footer/BendableLabsLogo';

import {
  maineDigest,
  heroImage,
  columnWrapper,
  headingLogo,
  headingWrapper,
  heading,
  instance,
  subheading,
  dateline,
  textWrapper,
  whatsNew,
  colWrapper,
  leftCol,
  rightCol,
  photoCaption
} from './maine_digest_2022_06.module.scss';

import BendableLogo from '../svg/Bendable_Logo.svg';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


const MaineDigestPage = () => {
  return (
    <Layout pageTitle="Bendable Maine">

      <div id="page" className="Site" >
        <a className="skip-link screen-reader-text" href="#primary">Skip to content</a>

        <header id="masthead" className="Site-header">
          <div className="Site-branding">
            <a href="/" className="custom-logo-link" rel="home"><img width="236" height="74" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/bendable-logo.svg" className="custom-logo" alt="Bendable" /></a>		</div>
          
            <div className="Community-header">
              <div className="Community-name">Maine</div>  			<div className="back">
                <Link to="/maine">
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15 7.3132C15 7.56181 14.9012 7.80024 14.7254 7.97603C14.5496 8.15183 14.3112 8.25059 14.0626 8.25059H3.20194L7.22712 12.2739C7.31427 12.361 7.38341 12.4645 7.43058 12.5784C7.47775 12.6923 7.50202 12.8143 7.50202 12.9376C7.50202 13.0608 7.47775 13.1829 7.43058 13.2967C7.38341 13.4106 7.31427 13.5141 7.22712 13.6012C7.13996 13.6884 7.0365 13.7575 6.92262 13.8047C6.80875 13.8519 6.6867 13.8761 6.56344 13.8761C6.44019 13.8761 6.31814 13.8519 6.20426 13.8047C6.09039 13.7575 5.98692 13.6884 5.89977 13.6012L0.275395 7.97687C0.188099 7.8898 0.118838 7.78635 0.0715815 7.67247C0.0243247 7.55858 0 7.4365 0 7.3132C0 7.1899 0.0243247 7.06781 0.0715815 6.95392C0.118838 6.84004 0.188099 6.7366 0.275395 6.64952L5.89977 1.02515C6.07578 0.84913 6.31452 0.750244 6.56344 0.750244C6.81237 0.750244 7.0511 0.84913 7.22712 1.02515C7.40314 1.20117 7.50202 1.4399 7.50202 1.68882C7.50202 1.93775 7.40314 2.17648 7.22712 2.3525L3.20194 6.3758H14.0626C14.3112 6.3758 14.5496 6.47456 14.7254 6.65036C14.9012 6.82615 15 7.06458 15 7.3132Z" fill="#A8A8A8"/>
                  </svg>
                  &nbsp;Back
                </Link>
              </div>
            </div>
          
        </header>

        <main id="primary" className="Site-main">
          <div className={maineDigest}>
            <article id="post-41" className="post-41 communities type-communities status-publish has-post-thumbnail hentry">
              <div>&nbsp;</div>
              <div className={columnWrapper}>
                <div>
                  <div className={headingLogo}>
                    <BendableLogo style={{ height: '100%' }} />
                  </div>
                  <div className={headingWrapper}>
                    <div className={heading}>Bendable <span className={instance}>Maine</span> Digest</div>
                    <div className={subheading}>A monthly update on the lifelong learning system being shaped by Mainers for Mainers</div>
                    <div className={dateline}>June 2022</div>
                  </div>
                </div>

                
                <div className={textWrapper}>

                  <div className={whatsNew}>What's New?</div>

                  <p>
                  We’ve always described Bendable as “the lifelong learning system being
                  shaped by Mainers for Mainers.”
                  </p>
                  <p>
                  Nowhere will this ethos be more on display, when we launch Bendable in
                  August, than with Community Collections—learning playlists put together by
                  residents across the state on topics in which they have expertise.
                  </p>
                  <p>
                  Take Bethany Zell, a certified health and wellness coach in Caribou. After
                  losing more than 100 pounds, Bethany started her own coaching business,
                  ZellBeing Wellness, where she has “loved being able to share how I was able
                  to get off the dieting roller coaster and improve my overall well-being.”
                  </p>


                  <div className={colWrapper}>
                    <div className={leftCol}>
                      <p>
                      Now, thanks to Bendable Maine, Bethany is set to
                      share her knowledge more widely. Her collection,
                      titled “Finding wholeness through health and
                      wellness,” includes links to a wellness
                      assessment, a video on the importance of sleep
                      and how to incorporate essential oils for
                      improving it, an app that helps you build healthier
                      habits and other great learning resources.
                      </p>

                      <p>
                      Dozens of Community Collections besides
                      Bethany’s are now in the works. Among them:
                      Old Town resident Angela Oechslie on getting
                      started as a Maine podcaster, Rockland resident
                      Greg Marley on foraging for mushrooms, Bangor
                      resident Nellie Kelly on getting your children
                      involved in the arts and Maya Williams, the
                      Portland poet laureate, on using poetry and
                      media to talk about mental health.
                      </p>

                      <p>
                      Just wait until you see what Maine knows!
                      </p>

                    </div>

                    <div className={rightCol}>
                      <StaticImage src="https://bendable.s3.us-west-1.amazonaws.com/bmd_BethanyZell.png" alt="Bethany Zell" />
                      <div className={photoCaption}>
                        Bethany Zell is sharing her
                        knowledge with her
                        fellow Mainers by authoring
                        a Community Collection
                        on Bendable.
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </article>
          </div>
        </main>

        <footer id="colophon" className="Site-footer">
          <div className="Site-info">
            <div className="Site-logos">
              <a href="https://www.maine.gov/msl/" target="_blank" rel="noreferrer">
                <img width="146" height="146" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-State-Library-Logo.png" className="attachment-full size-full" alt="" loading="lazy" />
              </a>

              <div style={{ width: '60px', height: '30px' }} />
              
              <BendableLabsLogo
                width = {{ xs: '150px', sm: '200px', md: '200px', lg: '200px', xl: '250px' }}
              />

            </div>		
            
          </div>
          
          {/* <nav id="site-navigation" className="Site-navigation">
            <div className="menu-menu-container">
              <ul id="footer-menu" className="menu"><li id="menu-item-200" className="dot menu-item menu-item-type-custom menu-item-object-custom menu-item-200"><a href="https://drucker.institute/programs/city-of-lifelong-learning/">Network</a></li>
                <li id="menu-item-201" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-201"><a href="mailto:support@bendable.com">Tech Support</a></li>
                <li id="menu-item-202" className="dot menu-item menu-item-type-custom menu-item-object-custom menu-item-202"><a href="https://network.bendable.com/privacy-policy">Privacy Policy</a></li>
                <li id="menu-item-203" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-203"><a href="https://network.bendable.com/terms-of-service">Terms of Service</a></li>
              </ul>
            </div>
          </nav> */}
          
        </footer>
      </div>

      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/navigation.js?ver=1.0.0' id='bendable-navigation-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/flickity.js?ver=2.2' id='flickity-scripts-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/mobile-flickity-slider.js?ver=2.2' id='custom-flickity-scripts-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/wp-embed.min.js?ver=5.7.2' id='wp-embed-js'></script>
    </Layout>
  )
}

export default MaineDigestPage;